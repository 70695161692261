import React from "react";
import { pdfjs } from "react-pdf";
import { Button, Typography, Upload as UploadAntd, Spin } from "antd";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./style.css";
import { LoadingOutlined } from '@ant-design/icons';
// import {baseURL} from '../../Api/index';
const { Text, Title } = Typography;

const baseURL = "";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const UploadButton = ({ label, desc, loading }: any) => {
  return (
    <Button className="upload-btn" type="primary" block>
      {loading ? <Spin className="uploadSpin" size="small" style={{marginRight: 12, color: '#fff'}} indicator={antIcon} /> : <PlusOutlined style={{ fontSize: 18, verticalAlign: 'middle' }} />} {label}
    </Button>
  );
};

const UploadPreviewContainer = ({
  file,
  onRemove,
  documentId,
  loading,
  isDownload,
}: any) => {
  const fileName = file?.name || "dosya";

  const previewFile = () => {
    window.open(`${baseURL}/Document/${documentId}`);
  };

  return (
    <div className="previewContainerStyle">
      <CloseCircleOutlined
        onClick={onRemove}
        style={{ fontSize: "18px", color: "red" }}
      />
      <Text
        style={{
          textAlign: "center",
          fontWeight: "bold",
          display: "block",
          marginTop: 3,
        }}
      >
        {fileName}
      </Text>

      {isDownload && (
        <Button type="link" size="small" onClick={previewFile} block>
          <Title
            level={5}
            style={{ color: "#09598c", textDecoration: "underlined" }}
          >
            İncele
          </Title>
        </Button>
      )}
    </div>
  );
};

const Upload = ({
  label = "",
  desc = "",
  fileList = [],
  height = 60,
  isDownload = true,
  ...props
}: any) => {
  let file: any = fileList?.[0];
  file = file?.originFileObj || file;

  const handleImageUpload = () => {
    return false; // to prevent automatic request of ANTD Upload component
  };

  return (
    <div style={{ height: height || 100 }}>
      <UploadAntd
        fileList={fileList}
        accept={props.accept}
        itemRender={() => (
          <UploadPreviewContainer
            documentId={props.documentId}
            file={file}
            onRemove={props.onRemove}
            isDownload={isDownload}
          />
        )}
        beforeUpload={handleImageUpload}
        {...props}
      >
        {fileList?.length ? null : <UploadButton loading={props.loading} label={label} desc={desc} />}
      </UploadAntd>
    </div>
  );
};

export default Upload;
