import React, { useState, useEffect,useRef } from "react";
import { Row,  Col, Table , Button, DatePicker, Input, Space} from "antd";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import moment from 'moment';
import { getUnregisteredAgriculturistsApi,getUnregisteredBuyersTitleApi, getUnregisteredAgriculturistsTitleApi } from "../../services/agroApi";
import type { InputRef } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';

function ApplicantFarmers() {
 
  const [loading, setLoading] = useState(false);
  const [unregisteredAgriculturists, setUnregisteredAgriculturists] = useState<any>();
  const [buyersTitle, setBuyersTitle] = useState<any>();
  const [agriculturistNames, setAgriculturistNames] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const searchInput = useRef<InputRef>(null);
  // const today = new Date().toLocaleDateString("tr-TR");
  // const hours = new Date().getHours();
  // const minutes = new Date().getMinutes();

  const [filters, setFilters] = useState<any>({
    agriculturistCreatedDateBegin: '',
    agriculturistCreatedDateEnd: '',
    searchAgriculturistFullName: '',
    buyerTitles: '',
    searchBuyerTitle:'',
    searchAgriculturistTaxNumber:'',
    searchBuyerTaxNumber:''
  });

  const getUnregisteredAgriculturists  = async () => {  
    try {
      setLoading(true);
      const {
        agriculturistCreatedDateBegin,
        agriculturistCreatedDateEnd,
        searchAgriculturistFullName,
        buyerTitles,
        searchBuyerTitle,
        searchAgriculturistTaxNumber,
        searchBuyerTaxNumber,
      } = filters;     
      const filterStr = `${agriculturistCreatedDateBegin ? `agriculturistCreatedDateBegin=${moment(agriculturistCreatedDateBegin).format('YYYY-MM-DD')}&` : ''}
        ${agriculturistCreatedDateEnd ? `agriculturistCreatedDateEnd=${moment(agriculturistCreatedDateEnd).format('YYYY-MM-DD')}&` : ''}
        ${buyerTitles ? buyerTitles : ''}
        ${searchAgriculturistFullName ? searchAgriculturistFullName : ''}
        ${searchBuyerTitle ? `searchBuyerTitle=${searchBuyerTitle}&`: ''}
        ${searchAgriculturistTaxNumber ? `searchAgriculturistTaxNumber=${searchAgriculturistTaxNumber}&` : ''}
        ${searchBuyerTaxNumber ? `searchBuyerTaxNumber=${searchBuyerTaxNumber}&` : ''}
         `

      const response: any = await getUnregisteredAgriculturistsApi(filterStr.trim().replaceAll(/\n/g,'').replace(/\s{2,}/g,''), psize, page);
      if (response) {
        setLoading(false);
        setUnregisteredAgriculturists(response?.unregisteredAgriculturists?.data);
        setTotalDataCount(response?.unregisteredAgriculturists?.totalDataCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }
  const getUnregisteredBuyersTitle  = async () => {  
    try {
      const response: any = await getUnregisteredBuyersTitleApi();
      if (response) {
        setBuyersTitle(response?.buyerTitles)
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  } 

  const getUnregisteredAgriculturistsTitle  = async () => {  
    try {
      const response: any = await getUnregisteredAgriculturistsTitleApi();
      if (response) {
        setAgriculturistNames(response?.agriculturistNames)
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  } 
 
 const handleReset = (clearFilters: any) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      agriculturistCreatedDateBegin: '',
      agriculturistCreatedDateEnd: '',
      searchAgriculturistFullName: '',
      buyerTitles: '',
      searchBuyerTitle:'',
      searchAgriculturistTaxNumber:'',
      searchBuyerTaxNumber:''
    })
  };

  const checkDate = (checkDate: any, date1: any, date2:any) => {
    return moment(moment(checkDate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
  }

 
  const getColumnTimeProps = (dataIndex: string) => ({ 
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
      <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }} >
        <div style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih seçin veya aralık girin</div>
        <DatePicker.RangePicker
          onChange={(e: any) => {
            setSelectedKeys(e.length ? [e] : [])
            setFilters({
              ...filters,
              agriculturistCreatedDateBegin: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.agriculturistCreatedDateBegin,
              agriculturistCreatedDateEnd: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.agriculturistCreatedDateEnd,
              searchAgriculturistFullName: filters.searchAgriculturistFullName,
              buyerTitles: filters.buyerTitles,
              searchBuyerTitle:filters.searchBuyerTitle,
              searchAgriculturistTaxNumber:filters.searchAgriculturistTaxNumber,
              searchBuyerTaxNumber:filters.searchBuyerTaxNumber,
            })
          }}
          allowClear={false}
          style={{ width: 190 }}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <div style={{ marginTop: 15 }}>
          <Button
            role="reset"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
            size="small"
          >
            Sıfırla
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined  style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilter: (value: any, record: any) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : "",
    render: (text: string) => moment(text).format("DD-MM-YYYY")
  });

  interface DataType {
    key: string;
    buyerTaxId: string;
    taxId: string
  }
  
  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      searchBuyerTaxNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      searchBuyerTaxNumber: '',
    })
  };

  const handleSearch2 = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      searchAgriculturistTaxNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice2 = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      searchAgriculturistTaxNumber:''
    })
  };

  const getBuyerVKNSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='VKN no'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="searc-btn-table"
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value:any, record:any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible:any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const getSupplierVKNSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='VKN No'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice2(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="searc-btn-table"
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilterDropdownOpenChange: (visible:any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });


  const handleChange = (pagination: any, _filters: any) => {
     setpage(pagination.current);
     setPsize(pagination.pageSize);
     setFilters({
       ...filters,
       buyerTitles : _filters?.buyerTitle !== null ? _filters?.buyerTitle.map((item:any) => `buyerTitles=${item}&`).toString().split(',').join('') : filters.buyerTitle="",
       searchAgriculturistFullName : _filters?.fullName !== null ? _filters?.fullName.map((item:any) => `searchAgriculturistFullName=${item}&`).toString().split(',').join('') : filters.searchAgriculturistFullName="",
     })
     setFilteredInfo(_filters);
   };

  useEffect(() => {
    getUnregisteredAgriculturists();
    getUnregisteredBuyersTitle();
    getUnregisteredAgriculturistsTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [page,psize, filters])

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  }

  const columns: any = [
    {
      title: "Alıcı",
      dataIndex: "buyerTitle",
      key: "buyerTitle",
      width:130,
      filters: buyersTitle && buyersTitle.map((item:any) =>{ return {text: item, value: item} }),
      filteredValue: (filteredInfo && filteredInfo.buyerTitle) || null,
      onFilter: (value:string, record:any) => record.buyerTitle.includes(value),
      ellipsis: true,
      filterSearch: true,
      fixed: 'left',
      onHeaderCell: () => ({
        className: 'headerOneCol',
      }),
    },
    {
      title: "Alıcı VKN",
      dataIndex: "buyerTaxId",
      key: "buyerTaxId",
      width:100,
      ...getBuyerVKNSearchProps('buyerTaxId'),
    },
    {
      title: "Çiftçi Ünvanı",
      dataIndex: "fullName",
      key: "fullName",
      width:120,
      align: 'center',
      filters: agriculturistNames && agriculturistNames.map((item:any) =>{ return {text: item, value: item} }),
      filteredValue: (filteredInfo && filteredInfo.fullName) || null,
      onFilter: (value: string, record: any) => record.fullName.includes(value),
      ellipsis: true,
      filterSearch: true,
    },
    {
      title: "Çiftçi Telefon Numarası",
      dataIndex: "gsmNumber",
      key: "gsmNumber",
      width:120,
      align: 'center',
    },
    {
      title: "Çiftçi VKN",
      dataIndex: "taxId",
      key: "taxId",
      width:100,
      align: 'center',
      ...getSupplierVKNSearchProps('taxId'),
    },
    {
      title: "Alıcının Çiftçiyi Tanımlama Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      width:150,
      align: 'center',
      ...getColumnTimeProps('createdDate'),
    },
  ];

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Aday Çiftçiler</h3>
        </Col>
      </Row>
      {/* <Row>
        <Col span={24} style={{textAlign:'right'}}>
          <Button type="primary"  
            icon={<DownloadOutlined />} 
            style={{backgroundColor:'#008000'}}
            onClick={xlsxReport}
            >
            Excel İndir
          </Button>
        </Col>
      </Row> */}
      <Row>
      <Col span={24} style={{ margin: "0px auto" }}>    
          <Table
            className="w-100 invoiceTable"
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,                          
              showSizeChanger: true,
              current: page,
              pageSize:psize,
              total:totalDataCount, 
              onShowSizeChange: handleShowSize,
              
            }}
            onChange={handleChange}
            loading={loading} 
            dataSource={unregisteredAgriculturists}
            columns={columns}
            scroll={{
              x: 1200,
            }}
          />
        </Col>
      </Row>
    </main>
  );
};

export default ApplicantFarmers;
