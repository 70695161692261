import React, { useState, useEffect } from "react";
import { Row, Table, Col, DatePicker, Button, notification, Select, Form, Checkbox } from "antd";
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
import {  getReportHistoriesApi, getHistoriesXlsxApi } from "../../services/buyerApi";
import { getCorporationsListApi } from "../../services/financeApi";
import '../../../node_modules/moment/locale/tr';
import locale from '../../../node_modules/antd/es/date-picker/locale/tr_TR';



function SupplierUsageReport() {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);

  // const [buyersList, setBuyersList] = useState<any>([]);
  const [financialInstitutionTitles, setFinancialInstitutionTitles] = useState<any>([]);

  // const [displayedValues, setDisplayedValues] = useState<string[]>([]);
  const [displayedValues1, setDisplayedValues1] = useState<string[]>([]);

  const [selectedValuesFinancial, setSelectedValuesFinancial] = useState<any>([]);
  // const [selectedValuesSuppliers, setSelectedValuesSuppliers] = useState<any>([]);
  const [reportData, setReportData] = useState<any>([]);

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState('');

  const today = new Date().toLocaleDateString("tr-TR");
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();

  const [filters, setFilters] = useState<any>({
    invoiceDateBegin: '',
    invoiceDateEnd: '',
    financialInstitutionNames: '',
    // buyerTitles: '',
    // searchBuyerTaxNumber:'',
    // ownerTitles:'',
    // searchOwnerTaxNumber:'',
    // status:''
  });

  const [form] = Form.useForm();

  const disabledDate = (current: any) => {
    return (
      (new Date(current).getDay() === 0 ||
        new Date(current).getDay() === 6)
    );
  }

  // const getBuyersLists = async () => {
  //   try {
  //     const response: any = await getBuyersListApi();
  //     if (response) {
  //       setBuyersList(response.buyers);
  //     }
  //   } catch (e: any) {
  //     console.log(e)
  //   }
  // };

  const getFinanceNames = async () => {
    try {
      const response: any = await getCorporationsListApi();
      if (response) {
        setFinancialInstitutionTitles(response.financialInstitutions);
      }
    } catch (e: any) {
      console.log(e)
    }
  };

  const getInvoicesHistoriesReport = async () => {
    const financialInstitutionNames = selectedValuesFinancial !== null ? selectedValuesFinancial.map((item: any) => `financialInstitutionNames=${item}&`).toString().split(',').join('') : filters.financialInstitutionNames = "";

    const {
      invoiceDateBegin,
      invoiceDateEnd,
      // buyerTitles,
      // searchBuyerTaxNumber,
      // ownerTitles,
      // searchOwnerTaxNumber,
      // status,
    } = filters;
    const filterStr = `${invoiceDateBegin ? `invoiceDateBegin=${moment(invoiceDateBegin).format('YYYY-MM-DD')}&` : ""}
      ${invoiceDateEnd ? `invoiceDateEnd=${moment(invoiceDateEnd).format('YYYY-MM-DD')}&` : ""}
      ${selectedValuesFinancial ? financialInstitutionNames.trim().replaceAll(/\n/g, '').replace(/\s{2,}/g, '') : ""}
       `

    try {
      setLoading(true)
      const response: any = await getReportHistoriesApi(filterStr.trim().replaceAll(/\n/g, '').replace(/\s{2,}/g, ''), pageNumber, pageSize);
      if (response) {
        setReportData(response.invoiceRecordHistories.data)
        setTotalDataCount(response.invoiceRecordHistories.totalDataCount);
        setLoading(false)
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
    }
  };

  const allValues = financialInstitutionTitles.map((item: any) => item.name);
  const handleChangeFinancial = (values: string[]) => {
    setShow(false);
    setReportData([]);
    if (values.includes("all")) {
      if (selectedValuesFinancial.length === allValues.length) {
        setSelectedValuesFinancial([]);
        setDisplayedValues1([]);
      } else {
        setSelectedValuesFinancial(allValues);
        setDisplayedValues1(["all"]);
      }
    } else {
      setSelectedValuesFinancial(values);
      setDisplayedValues1(values);
    }
  };

  // const allValuesBuyers = buyersList.map((item:any) => item.id.toString());
  // const handleChangeSuppliers = (values: string[]) => {
  //   setShow(false);
  //   setDashBoardSupplierUsages([]);
  //   if (values.includes("all")) {
  //     if (selectedValuesSuppliers.length === allValuesBuyers.length) {
  //       setSelectedValuesSuppliers([]);
  //       setDisplayedValues([]);
  //     } else {
  //       setSelectedValuesSuppliers(allValuesBuyers);
  //       setDisplayedValues(["all"]);
  //     }
  //   } else {
  //     setSelectedValuesSuppliers(values);
  //     setDisplayedValues(values);
  //   }
  // };

  const startDateChange = (val: any) => {
    // setDisplayedValues([]);
    setDisplayedValues1([]);
    setSelectedValuesFinancial([]);
    // setSelectedValuesSuppliers([]);
    setShow(false);
    if (val !== null) {
      setStartDate(val);
      setFilters({
        ...filters,
        invoiceDateBegin: val.toISOString(),
      })
    } else {
      setStartDate('')
    }
  }

  const endDateChange = (val: any) => {
    if (val !== null) {
      setFilters({
        ...filters,
        invoiceDateEnd: val.toISOString(),
      })
    }
  }

  const onchangeValue = async () => {
    if (Object.keys(selectedValuesFinancial).length > 0) {
      if (startDate !== '') {
        getInvoicesHistoriesReport();
        setShow(true);
      } else {
        notification.warning({
          message: `Başlangınç tarihi zorunlu seçilmeli.`,
        });
        form.resetFields();
        setShow(false);
      }
    } else {
      notification.warning({
        message: `Tedarikçi ve Finans kurumu seçimi yapmalısınız.`,
      });
    }
    // eslint-disable-next-line no-console
  };

  const handleShowSize = (current: any, size: any) => {
    setPageNumber(current);
    setPageSize(size);
  };

  const tableCols: any = [
    {
      title: "Banka Adı",
      dataIndex: "financialInstitutionName",
      key: "financialInstitutionName",
      width: 150,
      fixed: 'left',
    },
    {
      title: "Başvuru Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 120,
      render: (value: string) => moment(value).format("DD-MM-YYYY"),
      align: 'center',
    },
    {
      title: "Başvuru Numarası",
      dataIndex: "applicationNumber",
      key: "applicationNumber",
      width: 120,
      align: 'center',
    },
    {
      title: "Alıcı VKN",
      dataIndex: "buyerTaxNumber",
      key: "buyerTaxNumber",
      width: 120,
      align: 'center',
    },
    {
      title: "Alıcı Adı",
      dataIndex: "buyerTitle",
      key: "buyerTitle",
      width: 200,
      align: 'center',
    },
    {
      title: "Tedarikçi/Çiftçi VKN",
      dataIndex: "ownerTaxNumber",
      key: "ownerTaxNumber",
      width: 120,
      align: 'center',
    },
    {
      title: "Tedarikçi/Çiftçi Adı",
      dataIndex: "ownerTitle",
      key: "ownerTitle",
      width: 200,
      align: 'center',
    },
    {
      title: "Fatura Numarası",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 160,
      align: 'center',
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 120,
      render: (value: string) => moment(value).format("DD-MM-YYYY"),
      align: 'center',
    },
    {
      title: "Fatura Vadesi",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      width: 120,
      render: (value: string) => moment(value).format("DD-MM-YYYY"),
      align: 'center',
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      width: 120,
      align: 'center',
    },
  ];

  const xlsxReport = async () => {
    try {
      setLoading(true);
      const financialInstitutionNames = selectedValuesFinancial !== null ? selectedValuesFinancial.map((item: any) => `financialInstitutionNames=${item}&`).toString().split(',').join('') : filters.financialInstitutionNames = "";

      const {
        invoiceDateBegin,
        invoiceDateEnd,
        // buyerTitles,
        // searchBuyerTaxNumber,
        // ownerTitles,
        // searchOwnerTaxNumber,
        // status,
      } = filters;
      const filterStr = `${invoiceDateBegin ? `invoiceDateBegin=${moment(invoiceDateBegin).format('YYYY-MM-DD')}&` : ""}
        ${invoiceDateEnd ? `invoiceDateEnd=${moment(invoiceDateEnd).format('YYYY-MM-DD')}&` : ""}
        ${selectedValuesFinancial ? financialInstitutionNames.trim().replaceAll(/\n/g, '').replace(/\s{2,}/g, '') : ""}
         `

      const response: any = await getHistoriesXlsxApi(filterStr.trim().replaceAll(/\n/g, '').replace(/\s{2,}/g, ''), pageNumber, pageSize);
      if (response) {
        const link = document.createElement('a');
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(response.xlsxReport);
        link.setAttribute('download', `depar.io -  fatura raporu ${today} ${hours}.${minutes}.xlsx`);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if (startDate) {
      getInvoicesHistoriesReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  useEffect(() => {
    // getBuyersLists();
    getFinanceNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">İşlem Mutabakat Raporu</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            name="filter-box"
            autoComplete="off"
            labelCol={{
              span: 8,
            }}
            size="large"
            style={{
              minWidth: 500,
            }}
          >
            <div className='filter-row suppliers-movie' style={{ alignItems: 'end', justifyContent: 'flex-start' }}>
              <div>
                <span style={{ display: 'flex', alignItems: 'center', paddingLeft: '1px' }}>Başlangıç Tarihi<pre style={{ marginBottom: '0', marginTop: '0', color: '#f00', fontSize: '11px' }}> *</pre></span>
                <Form.Item
                  name='startDate'
                >
                  <DatePicker
                    locale={locale}
                    format='DD/MM/YYYY'
                    placeholder='Tarih Seçiniz'
                    disabledDate={disabledDate}
                    onChange={(value) => startDateChange(value)}
                  />
                </Form.Item>
              </div>
              <div>
                <span style={{ paddingLeft: '1px' }}>Bitiş Tarihi</span>
                <Form.Item
                  name='endDate'
                >
                  <DatePicker
                    locale={locale}
                    format='DD/MM/YYYY'
                    placeholder='Tarih Seçiniz'
                    disabledDate={disabledDate}
                    onChange={(value) => endDateChange(value)}
                  />
                </Form.Item>
              </div>

            </div>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p>Listelemek istediğiniz finans kurumunu veya finans kurumlarını seçiniz.</p>
          <div style={{ position: "relative", maxWidth: 500 }}>
            <Select
              mode="multiple"
              placeholder="Lütfen seçim yapınız."
              style={{ width: "100%" }}
              value={displayedValues1}
              onChange={handleChangeFinancial}
              dropdownRender={(menu) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <Checkbox
                      indeterminate={
                        selectedValuesFinancial.length > 0 &&
                        selectedValuesFinancial.length < allValues.length
                      }
                      checked={selectedValuesFinancial.length === allValues.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedValuesFinancial(allValues);
                          setDisplayedValues1(["all"]);
                        } else {
                          setSelectedValuesFinancial([]);
                          setDisplayedValues1([]);
                        }
                      }}
                    >
                      Hepsi
                    </Checkbox>
                  </div>
                  {menu}
                </div>
              )}
              options={[
                { value: "all", label: "Hepsi" },
                ...financialInstitutionTitles.map((item: any) => ({
                  value: item.name,
                  label: (
                    <Checkbox
                      checked={selectedValuesFinancial.includes(item.name)}
                      onChange={() => { }}
                    >
                      {item.name}
                    </Checkbox>
                  ),
                })),
              ]}
            />
            <div
              style={{
                position: "absolute",
                right: 10,
                top: "18px",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                fontWeight: "900",
              }}
            >
              <DownOutlined />
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row style={{ marginTop: '30px' }} >
        <Col style={{ margin: '0 0 20px' }} span={24}>
          <p>Lütfen hareketlerini görüntülemek istediğiniz tedarikçiyi seçiniz.</p>
          <div style={{ position: "relative", maxWidth: 500 }}>
            <Select
              mode="multiple"
              placeholder="Lütfen seçim yapınız."
              style={{ width: "100%" }}
              value={displayedValues}
              onChange={handleChangeSuppliers}
              dropdownRender={(menu) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <Checkbox
                      indeterminate={
                        selectedValuesSuppliers.length > 0 &&
                        selectedValuesSuppliers.length < allValuesSuppliers.length
                      }
                      checked={selectedValuesSuppliers.length === allValuesSuppliers.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedValuesSuppliers(allValuesSuppliers);
                          setDisplayedValues(["all"]);
                        } else {
                          setSelectedValuesSuppliers([]);
                          setDisplayedValues([]);
                        }
                      }}
                    >
                      Hepsi
                    </Checkbox>
                  </div>
                  {menu}
                </div>
              )}
              options={[
                { value: "all", label: "Hepsi" },
                ...buyersList.map((item: any) => ({
                  value: item.id.toString(),
                  label: (
                    <Checkbox
                      checked={selectedValuesSuppliers.includes(item.id.toString())}
                      onChange={() => { }}
                    >
                      {item.supplierTitle}
                    </Checkbox>
                  ),
                })),
              ]}
            />
            <div
              style={{
                position: "absolute",
                right: 10,
                top: "18px",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                fontWeight: "900",
              }}
            >
              <DownOutlined />
            </div>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <div className="filter-row">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onchangeValue}
              >Filtrele
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
      {
        show ?
          (
            <>
              <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button type="primary"
                    icon={<DownloadOutlined />}
                    style={{ backgroundColor: '#008000' }}
                    onClick={xlsxReport}
                  >
                    Excel İndir
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ margin: "0px auto" }}>
                  <Table
                    className="w-100 invoiceTable"
                    pagination={{
                      position: ["topRight", "none" as any],
                      showLessItems: true,
                      showSizeChanger: true,
                      current: pageNumber,
                      pageSize: pageSize,
                      total: totalDataCount,
                      onChange: handleShowSize,

                    }}
                    loading={loading}
                    dataSource={reportData}
                    columns={tableCols}
                    scroll={{
                      x: 1200,
                    }}
                  />

                </Col>
              </Row>
            </>
          ) :
          (
            ""
          )
      }



    </main>
  );
}

export default SupplierUsageReport;
