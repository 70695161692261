import React, { useEffect, useState } from "react";
import { Row, Col, Table, Button, Modal,notification} from "antd";
import { getUsersDataApi, deleteUserApi, forgotPasswordApi } from "../../services/userAuth";
import { useNavigate } from "react-router-dom";


function UserTracing() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [users, setUsers] = useState<any>();
  const [deleteUser, setDeleteUser] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
 
  const columns : any = [
    {
      title: 'Kullanıcı',
      dataIndex: 'email',
      key: "email",
      fixed: 'left',
      width: 200,
      sorter: {
        compare: (a:any, b:any) => a.taxId - b.taxId,
        multiple: 2,
      },
    },
    {
      title: 'Telefon Numarası',
      dataIndex: 'phoneNumber',
      key: "phoneNumber",
      width: 150,
    },
    {
      title:'Kullanıcı Rolü',
      dataIndex:'userRole',
      key:'userRole',
      width: 120,     
      render: (text: any, row: any) =>  
        <span> 
          {row?.userRole === 'SUPERVISOR' ? 'ADMİN' : 'KULLANICI'}
        </span>         
  },
    {
      title:'',
      dataIndex:'detail',
      key:'detail',
      width: 120,     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          onClick={()  => navigate(`/user-detail/${row?.id}`)}
          > Detay
        </Button>         
  },
  {
    title:'',
    dataIndex:'update',
    key:'update', 
    width: 120,     
    render: (text: any, row: any) =>  
      <Button 
        type="primary" 
        onClick={()  => navigate(`/user-update/${row?.id}`)}
        > Güncelle
      </Button>         
    },
    {
      title:'',
      dataIndex:'delete',
      key:'delete',  
      width: 120,       
      render: (text: any, row: any) =>  
        <Button 
          style={{backgroundColor:'#F15A31', border:'#f25429'}}
          type="primary" 
          onClick={ () =>  showModal(row?.id) }
          > Sil
        </Button>         
    },
    {
      title:'',
      dataIndex:'passwordChange',
      key:'passwordChange',     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          onClick={ () =>  forgotPassword(row?.email) }
          > Şifre Sıfırla
        </Button>         
    },
    
  ];
  
const forgotPassword = async (email:any) => {
  const data = {
    email: email
  }
  try{
      await forgotPasswordApi(data);
      notification.success({
      message: `Lütfen mailinizi kontrol ediniz.`,
    });
  }catch(e:any){
    console.log(e);
  }
      
}

  const getUsersData = async () => {
   setLoading(true)
    const response: any = await getUsersDataApi();
    if(response){
      setUsers(response.users)
      setLoading(false)
      setTotalDataCount(10)
    }
  }

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  const deleteUsers = async () => {
    if(deleteUser){
      try {
        await deleteUserApi(deleteUser);
          notification.success({
            message: `Kullanıcı başarıyla silinmiştir.`,
        }); 
          getUsersData();
          setDeleteUser('');
        
      } catch (error: any) {
        console.log(error);
      }
    }
    
  }

  const showModal = (id:any) => {
    setOpen(true);
    setDeleteUser(id)
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
     deleteUsers();
  };
  const handleCancel = () => {
    setOpen(false);
  };
  

  useEffect(() => {
    getUsersData()
  },[])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Kullanıcı Listesi</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Table
           className="w-100 invoiceTable"
           style={{width:'auto'}}
           pagination={{
             position: ["topRight", "none" as any],
             showLessItems: true,                          
             showSizeChanger: true,
             current: page,
             pageSize:psize,
             total:totalDataCount, 
             onChange: handleShowSize,
             
           }}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={users}
          scroll={{
            x: 1200,
          }}
          
        />
        </Col>
      </Row>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
        className="user-delete-modal"
      >
        <p>İşlemi yapmak istediğinizden emin misiniz?</p>
      </Modal>

    </main>
  );
};

export default UserTracing;
