import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Select, Upload, notification, Divider } from "antd";
import { FileOutlined, CheckCircleOutlined, ExclamationCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { getBuyersListApi, getUploadSupplierApi, getDownloadSampleSupplierFileApi } from "../../services/buyerApi";
import { useSelector, useDispatch } from 'react-redux';
import { setBuyersListData } from "../../reducers/buyerSlice"


const BuyerUploadSupplier = () => {
  const dispatch = useDispatch();
  const [buyerSelectInvoice, setBuyerSelectInvoice] = useState<any>();
  const [selectBuyerTitle, setSelectBuyerTitle] = useState<any>('');
  const [selectBuyerId, setSelectBuyerId] = useState<any>('');
  const [dataReport, setDataReport] = useState<any>([]);
  const [dataActive, setDataActive,] = useState(false);
  const [fileList, setFileList] = useState<any>([]);
  const [isLoading, setIsLoading,] = useState(false);
  const [form] = Form.useForm();
  const { buyerList } = useSelector((state: any) => state.buyer);

  const handleInput = (getBuyerId: any) => {
    setSelectBuyerId(getBuyerId)
    setSelectBuyerTitle(
      buyerList.filter((item: any) => item.id === getBuyerId)
    )
  }

  const beforeUpload = () => {
    // Prevent upload
    return false
  }

  const handleChange = (info: any) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
    setBuyerSelectInvoice(fileList[0]?.originFileObj.name)
  };

  const downloadSample = () => {
    if (dataReport) {
      const link = document.createElement('a');
      link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(dataReport.excelReportFileBase64);
      link.setAttribute('download', 'Hata Raporu - Hata Raporu.xlsx');

      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log('hata oluştu')
    }
  };

  const getBuyerUploadSupplier = async () => {
    const formData = new FormData();
    formData.append('file', fileList[0]?.originFileObj)
    if (fileList.length > 0 && fileList !== undefined) {
      try {
        const response = await getUploadSupplierApi(selectBuyerId, encodeURIComponent(selectBuyerTitle[0].title), formData);
        if (response) {
          setDataActive(true)
          setDataReport(response)
        }
      } catch (e: any) {
        if(e.data.Status === 400 && e.data.Type.includes('InvalidFileFormatException')){
          notification.warning({
              message: `Yüklemiş olduğunuz dosya formatı hatalıdır. Örnek formatı Tedarikçi Yükle sayfamızdan indirerek oluşturabilirsiniz.`,
            });
          };
        if (e.status === 400 && e.data.Type.includes('InvalidFileTypeException')) {
          notification.warning({
            message: `Yalnızca .xls uzantılı dosya yükleyebilirsiniz!`,
          });
        }
        if (e.status === 400 && e.data.Type.includes('InvalidSizeFileException')) {
          notification.warning({
            message: `Dosyanızın boyutu maksimum 1MB olmalıdır!`,
          });
        }
        if (e.status === 400 && e.data.Type.includes('EmptyFileFormatException')) {
          notification.warning({
            message: `Yüklediğiniz tedarikçi listesinde veri yoktur. Lütfen kontrol ediniz ve tekrar yükleyiniz.`,
          });
        }
      }
    } else {
      notification.warning({
        message: `Dosya seçmediniz.`,
      });
    }
  }

  const getBuyersList = async () => {
    try {
      const response: any = await getBuyersListApi();
      if (response) {
        const filteredBuyers = response.buyers.filter((buyer: any) => buyer.typeCode === "TEDARIKCI_ALICISI");
        dispatch(setBuyersListData(filteredBuyers));
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  const downloadSampleFile = async () => {
    setIsLoading(true)
    try {
      const response: any = await getDownloadSampleSupplierFileApi();
      if (response) {
        setIsLoading(false)
        const link = document.createElement('a');
        link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(response.supplierTemplate);
        link.setAttribute('download', 'Örnek dosya - Örnek Dosya.xlsx');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getBuyersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title"> Alıcı Adına Tedarikçi Yükleme</h3>
        </Col>
      </Row>
      <Row style={{ paddingTop: '0' }}>
        <Col span={24}>
          <Form
            form={form}
            name="user-update"
            className="default-form"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="vertical"
            initialValues={{
              size: 'large',
            }}

            size={'large'}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              label="Alıcı Seçiniz:"
              name="buyerId"
              rules={[
                {
                  required: true,
                  message: 'Lütfen finans kurumu seçiniz.',
                },
              ]}
            >
              <Select onChange={(e: any) => handleInput(e)}>
                {
                  buyerList && buyerList.map((item: any, index: any) => {
                    return (
                      <Select.Option key={index} value={item.id} >{item.title}</Select.Option>
                    )
                  })
                }

              </Select>
            </Form.Item>

            <Form.Item style={{ marginTop: '20px' }}>
              <Upload beforeUpload={beforeUpload} onChange={handleChange} maxCount={1} disabled={selectBuyerId === ''}>
                <Button icon={<DownloadOutlined />}  loading={isLoading}>Dosya Yükle</Button>
              </Upload>
            </Form.Item>

            <Form.Item style={{ marginTop: '25px' }}>
              <Button type="primary" onClick={getBuyerUploadSupplier} 
                disabled={selectBuyerId === ''}
                loading={isLoading}
              >Tedarekçi Özetini Yükle</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {
        dataActive ? '' : (
          <Row>
            <Col md={8} lg={8} xs={24}>
              <Divider />
              <Button
                className="download-btn"
                type="default"
                loading={isLoading}
                block
                onClick={downloadSampleFile}
                style={{ width: '250px' }}
              >
                <DownloadOutlined style={{ fontSize: 20 }} /> Örnek Dosyamızı İndirin
              </Button>
            </Col>
          </Row>
        )
      }

      {
        dataActive && (
          <Row>
            <Col span={24}>
              <hr style={{ marginBottom: '30px' }} />
              <p className="title" style={{ marginBottom: '30px' }}> En son yüklenmiş olan
                <span> {buyerSelectInvoice && buyerSelectInvoice} </span>   fatura Özeti</p>
              <div className="sum-information">
                <div className="box box1">
                  <div className="icon-box">
                    <FileOutlined />
                  </div>
                  <span className="txt3">Toplam</span>
                  <span className="txt5">{dataReport?.totalLoadedSupplierCount}  Adet</span>
                </div>

                <div className="box box3">
                  <div className="icon-box">
                    <CheckCircleOutlined />
                  </div>
                  <span className="txt3">Başarılı</span>
                  <span className="txt5">{dataReport?.totalValidSupplierCount}  Adet</span>
                </div>

                <div className="box box4">
                  <div className="icon-box">
                    <ExclamationCircleOutlined />
                  </div>
                  <span className="txt3">Hatalı</span>
                  <span className="txt5">{dataReport?.totalInvalidSupplierCount} Adet</span>
                  <Link to='' onClick={downloadSample} className="link" >Hata Raporu</Link>
                </div>
              </div>
            </Col>
          </Row>
        )
      }

    </main>
  );
};

export default BuyerUploadSupplier;
