import React,{useState} from "react";
import { Row, Col,Button, Form, Input,notification,Modal } from "antd";
import {validateEmail} from "../../helpers/validators";
import {dummyDataDeleteApi} from '../../services/buyerApi'

function DummyDataDelete() {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [email, setEmail] = useState('');

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    if(email !== ''){
      try{
        await dummyDataDeleteApi(email);
          form.resetFields();
          setConfirmLoading(false);
          setOpen(false);
          setEmail('')
        }catch(e:any){
          console.log(e);
          setConfirmLoading(false);
          setOpen(false);
          setEmail('')
        }
    }else{
      setConfirmLoading(false);
      setEmail('');
      setOpen(false);
      notification.error({
        message: `Bir hata oluştu tekrar deneyiniz.`,
      });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <main>
      <Row >
        <Col span={24}>
          <h3 className="page-title"> Alıcı/Tedarikçi Sil</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={8} xs={18} md={12}>
        <Form
          form={form}
          name="email-form"
          layout="vertical"
        >
          <Form.Item
            label="Silmek istediğiniz hesaba ait email adresini giriniz."
            name="email"
            rules={[
              {
                required: true,
                validator: (_, value) => validateEmail(value)
              },
            ]}
          >
            <Input placeholder="E-posta adresinizi giriniz."  onChange={(e) => setEmail(e.target.value)}/>
          </Form.Item>

          <Form.Item style={{marginTop:'20px'}}>
            <Button type="primary" onClick={showModal} disabled={email === ''}>
              Bilgileri Sil
            </Button>
          </Form.Item>
        </Form>
        </Col>
      </Row>
      <Modal
        open={open}
        className="supplier-transaction"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
        closable={false}
        maskClosable={false}
      >
        <p><strong>{email}</strong>, alıcı ve tedarikçi bilgileri geri dönülemez bir şekilde silinecektir, onaylıyor musunuz?</p>
      </Modal>
     
    </main>
  );
};


export default DummyDataDelete;
