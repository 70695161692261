import {apiAgro} from '../services/apis';

const getDiscountsApi = async (psize:any,page:any,searchQuery:any): Promise<Object> => {
  return searchQuery ?
   await apiAgro.get(`admin/discounts?pageNumber=${page}&pageSize=${psize}&searchQuery=${searchQuery}`,
    {}
  ):
  await apiAgro.get(`admin/discounts?pageNumber=${page}&pageSize=${psize}`,
    {}
  )
};

const getUnregisteredBuyersTitleApi = async (): Promise<Object> => {
  return await apiAgro.get(`admin/agriculturists/unregistered/buyer-titles`,
    {}
  );
};

const getUnregisteredAgriculturistsApi = async (filters: string, psize:any,page:any): Promise<Object> => {
  return await apiAgro.get(`admin/agriculturists/unregistered?${filters ? `${filters}`:''}pageNumber=${page}&pageSize=${psize}`, {} );
};

const getUnregisteredAgriculturistsTitleApi = async (): Promise<Object> => {
  return await apiAgro.get(`admin/agriculturists/unregistered/agriculturist-names`,
    {}
  );
};

const getTxnHistoryByTaxIdApi = async (id: any): Promise<Object> => {
  return await apiAgro.get(`admin/discounts/${id}`,
    {}
  );
};

const getAgriculturistDatas = async (filters: string, page:any,psize:any): Promise<Object> => {
  return await apiAgro.get(`admin/agriculturists/paged?pageNumber=${page}&pageSize=${psize}${filters ? `${filters}`:''}`,
    {}
  );
};

const getAgriculturistDetailApi = async (discountId: any): Promise<any> => {
  return await apiAgro.get(`admin/agriculturist/${discountId}`,
    {}
  );
};

const updateAgriculturistApi = async (supplierId:any, newData: any): Promise<Object> => {
  return await apiAgro.patch(`admin/agriculturist/${supplierId}`, newData, {} );
};

const deleteAgriculturistId = async (agriculturistId: any): Promise<Object> => {
  return await apiAgro.delete(`admin/agriculturist/${agriculturistId}`,
    {}
  );
};

const getFarmersListApi = async (buyerId:any): Promise<Object> => {
  return await apiAgro.get(`admin/agriculturists/${buyerId}/registered`,
    {}
  );
};



export {
  getDiscountsApi,
  getUnregisteredBuyersTitleApi,
  getUnregisteredAgriculturistsApi,
  getUnregisteredAgriculturistsTitleApi,
  getTxnHistoryByTaxIdApi,
  getAgriculturistDatas,
  getAgriculturistDetailApi,
  updateAgriculturistApi,
  getFarmersListApi,
  deleteAgriculturistId
  
};
