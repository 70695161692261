import React from "react";
import {  Row, Col} from "antd";
import Upload from "../../components/Upload";

const UploadBoxFarmer = ({  fileType, setFileType, isLoadingUpload, removeFile,  xmlFunction, setDataMustahsil,selectBuyerId }: any) => {

  
  return (
 
      <Row  style={{paddingTop:'0'}}>
      <Col span={24} className="mustahsil-screen">
        <Upload
          className="excel-yukle"
          label="E-müstahsil dosyası/ları Yükle"
          accept=".xml"
          fileList={[]}
          disabled={selectBuyerId === ''}
          loading={fileType === 'text/xml-mustahsil' && isLoadingUpload}
          onChange={(val: any) => {
            setFileType('text/xml-mustahsil');
            setDataMustahsil(
              val.fileList.map((item: any, index: any) => {
                return (
                  val.fileList[index].originFileObj
                )
              }
              )
            )
          }}
          onClick={xmlFunction}
          onRemove={removeFile}
          isDownload={false}
          
          multiple="true"
        />
      </Col>
    </Row>
 
  
  )
}

  
export default UploadBoxFarmer;

  