import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select,notification,Checkbox, Modal, Spin} from "antd";
import { useSelector} from 'react-redux';
import { useLocation } from "react-router-dom";
import {  getBuyerDetailApi, updateBuyerApi, getUndefinedFinancialApi, automaticBillingInformationApi, uploadInvoiceIntegratedBankApi} from "../../services/buyerApi";
import {getTaxAdministartions,getDistrictsApi} from "../../services/commonApi"
import {convertCurrencyTl} from "../../helpers/common";
import {validateVkn} from "../../helpers/validators";


function BuyerUpdate() {

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const location = useLocation();
  const buyerId = location.pathname.split('/')[2];
  const [districtList, setDistrictList] = useState<any>([]);
  const [taxAdministartion, setTaxAdministartion] = useState<any>([]);
  const {provinces} = useSelector((state: any) => state.common);

  const [loading, setLoading] = useState<any>(false);
  const [buyerDetail, setBuyerDetail] = useState<any>([]);
  const [buyerDefinitions, setBuyerDefinitions] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>([]);
  const [companyCode, setCompanyCode] = useState<any>([]);
  const [valorValue, setValorValue] = useState<any>([]);
  const [districtInput, setDistrictInput] = useState<any>([]);
  const [taxName, setTaxName] = useState<any>([]);
  const [check, setCheck] = useState<any>(false);
  const [checkIntegration, setCheckIntegration] = useState<any>(false);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [addFinances, setAddFinances] = useState<any>([]); 
  const [undefinedFinancial, setUndefinedFinancial] = useState<any>([]);  
  const [checkedItems, setCheckedItems] = useState<any>({});

  const checkedItemsCount = Object.keys(checkedItems).filter(key => checkedItems[key]).length;

 
   useEffect(() => {
    setCheckedItems({});
  }, [undefinedFinancial]);

  useEffect(() => {
    const trueItems = Object.keys(checkedItems).filter(key => checkedItems[key]);
    const idsArray2 = trueItems.map((item:any) => item);
    const uniqueArray1 = undefinedFinancial.filter((item:any) => idsArray2.includes(item.id));
    setAddFinances(uniqueArray1);
  }, [checkedItems]);
 

  const handleCheckboxChange = (item:any) => {
    setCheckedItems((prevState:any) => ({
      ...prevState,
      [item.id]: !prevState[item.id]
    }));
  };


  const getBuyerDetail =  async () => {
    try {
      const response: any = await getBuyerDetailApi(buyerId);
      if (response) {
        setBuyerDetail(response.buyer);
        setBuyerDefinitions(response.buyerDefinitions);
        setDistrictInput(response.buyer.state);
        setTaxName(response.buyer.taxAdministration)
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  const getUndefinedFinancial =  async () => {
    setLoading(true)
    try {
      const response: any = await getUndefinedFinancialApi(buyerId);
      if (response) {
        setUndefinedFinancial(response.financialInstitutions);
        setLoading(false)
      }
    } catch (error: any) {
      console.log(error);
    }
  }
 
  const onFinish =  async (values:any) => {
    let notificationMessage = "";
    Object.keys(values)
      .filter((key) => key.endsWith("companyCode"))
      .forEach((limitKey) => {
        const index: any = limitKey.match(/^\d+/)?.[0];
        if(limitKey){
          if(buyerDefinitions[index].companyCode === null  &&  companyCode[index] === undefined  ){
            notificationMessage += `${index}`;
          }
          if(buyerDefinitions[index].companyCode === ''  &&  companyCode[index] === undefined  ){
            notificationMessage += `${index}`;
          }
        }
            
      });

      if (notificationMessage !== '' ) {
        notification.error({
          message: `CompanyCode alanlarını boş bırakamazsınız. `,
        });
      } else{
        try {
          const financialID = buyerDefinitions && buyerDefinitions.map((item: any, index: any) => {
            return {
              financialInstitutionId: item.financialInstitutionId,
              limit: inputValue[index] === undefined ? item.limit : inputValue[index],
              delayValue: valorValue[index] === undefined ? item.delayValue : parseInt(valorValue[index]),
              companyCode: companyCode[index] === undefined ? item.companyCode : companyCode[index],
            }
          }).filter((item: any) => {
            if (item.financialInstitutionId !== '' &&
              item.limit !== '' &&
              item.delayValue !== '' &&
              item.companyCode !== '' &&
              item.financialInstitutionId !== undefined &&
              item.limit !== undefined &&
              item.delayValue !== undefined &&
              item.companyCode !== undefined) {
              return item
            }
            return true
          });

          const provinceName = provinces.filter((item: any) => {
            if (item.provinceId === values.province) {
              return item
            }
            return
          });
          const newData: any = {
            buyerTitle: values.title ? values.title : buyerDetail?.title,
            province: values.province ? provinceName[0].name : buyerDetail?.province,
            state: values.district ? values.district : buyerDetail?.state,
            taxAdministration: values.taxAdministration ? values.taxAdministration : buyerDetail?.taxAdministration,
            taxNumber: values.taxId ? values.taxId : buyerDetail?.taxId,
            address: values.address ? values.address : buyerDetail?.address,
            isIntegrated: checkIntegration,
            autoMarkInvoicesAsPaidEnabled: check,
            type:values.type ? values.type : buyerDetail?.typeCode,
            financialInstitutionLimits: financialID
          };
          await updateBuyerApi(buyerId ,  newData) ;
          window.location.reload();

        } catch (e: any) {
          console.log('e', e)
          if (e.data.Status === 404 && e.data.Type.includes('BuyerNotFoundException')) {
            notification.error({
              message: `Alıcı bulunamamıştır. `,
            });
          }

          if (e.data.Status === 400 && e.data.Type.includes('CompanyCodeAlreadyExistException')) {
            notification.error({
              message: `Girmiş olduğunuz CompanyCode farklı bir alıcıda tanımlıdır. `,
            });
          }
        } 
      }

   
  };

const getDistrictsTaxId = async (dataId:any) => {
  form.resetFields(['taxAdministration']);
  form.resetFields(['district']);
  setDistrictInput('');
  setTaxName('')
  try {
    const response: any = await getDistrictsApi(dataId);
    if (response) {
      setDistrictList(response);
    }
  } catch (error: any) {
    console.log(error);
  }


  try {
    const response: any = await getTaxAdministartions(dataId);
    if (response) {
      setTaxAdministartion(response);
    }
  } catch (error: any) {
    console.log(error);
  }
}

const onChange = (checkedValues:any) => {
  setCheck( checkedValues.target.checked)
}; 

const onChangeIntegration = (checkedValues:any) => {
  setCheckIntegration( checkedValues.target.checked)
}; 

const showModal = () => {
  setIsModalOpen(true);
};

const handleOk = () => {
  setIsModalOpen(false);
  if(addFinances){
    addFinances.map((item:any) => {
      return(
        buyerDefinitions.push(
          {
            financialInstitutionId:item.id,
            financialInstitutionName:item.name,
            financialInstitutionTaxId:item.taxId,
            companyCode:''
          }
        )
      )
    })

    const idsArray2 = addFinances.map((item:any) => item.id);
    const uniqueArray1 = undefinedFinancial.filter((item:any) => !idsArray2.includes(item.id));
    setUndefinedFinancial(uniqueArray1)
    setAddFinances([])
  }
};

const uploadInvoiceIntegratedBank = async () => {
  try{
  const response:any = await uploadInvoiceIntegratedBankApi(buyerId);
    if(response){
      notification.success({
        message: `${response}`,
        duration: 6,
        className:'info-not'
      });
    }
  }catch(e:any){
    console.log(e)
  }
}


const handleCancel = () => {
  setIsModalOpen(false);
};

const AutomaticBillingInformation = async () => {
  try{
    const response:any = await automaticBillingInformationApi(buyerDetail.taxId);
    if(response){
      notification.success({
        message: `${buyerDetail.title} otomatik fatura çekilme işlemi başlatılmıştır.`,
        duration: 6,
        className:'info-not'
      });
    }
  }catch(e:any){
    console.log(e)
  }
}

useEffect(() => {
  setCheck(buyerDetail.autoMarkInvoicesAsPaidEnabled)
  setCheckIntegration(buyerDetail.isIntegrated)
},[buyerDetail])

useEffect(() => {
  getBuyerDetail();
  getUndefinedFinancial();
     // eslint-disable-next-line react-hooks/exhaustive-deps   
},[])

  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title"> Alıcı Bilgi Güncelle</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="buyer-update-col">
          <Form
            form={form}
            name="user-update"
            className="default-form buyer-form-update"
            onFinish={onFinish}
            labelCol={{
            span: 10,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="vertical"
            initialValues={{
              size: 'large',
            }}
            size={'large'}
            style={{
              width: '100%',
            }}
            >
        
            <h4>Firma Bilgileri</h4>
              <Form.Item 
                label="Ünvan:"
                name="title"
              >
                <Input  placeholder={buyerDetail?.title}/>
              </Form.Item>

              <Form.Item 
                label="İl:"
                name="province"
                >
                <Select  
                  placeholder={buyerDetail?.province} 
                  optionFilterProp="children"
                  showSearch
                  onChange={(e:any) => getDistrictsTaxId(e)}
                  >
                  {
                  provinces && provinces.map((item:any, index:any) => {
                      return(
                        <Select.Option key={index} value={item.provinceId}>{item.name}</Select.Option>
                      )
                    })
                  }
                
                </Select>
              </Form.Item>
                <Form.Item 
                label="İlçe:"
                name="district"
                >
                <Select  
                  placeholder={districtInput} 
                  optionFilterProp="children"
                  showSearch
                  >
                  {
                  districtList && districtList.map((item:any, index:any) => {
                      return(
                        <Select.Option key={index} value={item}>{item}</Select.Option>
                      )
                    })
                  }
                
                </Select>
              </Form.Item>
                <Form.Item 
                label="Vergi Dairesi:"
                name="taxAdministration"
                >
                <Select  
                  placeholder={taxName}
                  optionFilterProp="children"
                  showSearch
                  >
                  {
                  taxAdministartion && taxAdministartion.length > 0 ? taxAdministartion.map((item:any, index:any) => {
                      return(
                        <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                      )
                    }): ''
                  }
                
                </Select>
              </Form.Item>
          
            
              <Form.Item 
                label="Vergi Kimlik Numarası:"
                name="taxId"
                rules={[
                  {
                    validator: (_, value) =>  validateVkn(value ? value : buyerDetail?.taxId )
                  },
                ]}
                >
                <Input placeholder={buyerDetail?.taxId} />
              </Form.Item>
              <Form.Item 
                label="Adres"
                name="address"
              >
                <TextArea
                rows={4} 
                placeholder={buyerDetail?.address}
                />
              </Form.Item>
              <Form.Item 
                label="Alıcı Türünü Seç:"
                name="type"
                >
                <Select  
                  optionFilterProp="children"
                  showSearch
                  placeholder={
                    buyerDetail?.typeCode === 'TEDARIKCI_ALICISI' ? 'Tedarikçi Alıcısı':
                    buyerDetail?.typeCode === 'CIFTCI_ALICISI' ? 'Çiftçi Alıcısı':
                    'Tedarikçi ve Çiftçi Alıcısı'
                  }
                  >
                  <Select.Option key='1' value='TEDARIKCI_ALICISI' >Tedarikçi Alıcısı</Select.Option>
                  <Select.Option key='2' value='CIFTCI_ALICISI' >Çiftçi Alıcısı</Select.Option>
                  <Select.Option key='3' value='CIFTCI_VE_TEDARIKCI_ALICISI' >Tedarikçi ve Çiftçi Alıcısı</Select.Option>
                </Select>
              </Form.Item>
              <div className="buyer-update-col-h4-ar">
                <h4 style={{margin:'0'}}>Finans Kurumları</h4>
                <Button onClick={uploadInvoiceIntegratedBank}>Otomatik Fatura Yüklemeyi Yeniden Tetikle</Button>
              </div>
             
              <div style={{maxWidth:'720px', overflowX:'auto', display:'flex'}}>
              <ul style={{paddingLeft:'0'}}  className="dev-label  dev-label-update">  
                <li className="buyer-update-li-title" style={{display:'flex', justifyContent:'flex-start', marginBottom:'10px'}}>
                  <span style={{width:'145px'}}></span>
                  <span style={{width:'165px'}}>Limit:</span>
                  <span style={{width:'65px'}}>Valör:</span>
                  <span style={{width:'130px'}}>Aıcı Riski:</span>
                  <span style={{width:'140px'}}>CompanyCode:</span>
                </li>   
                <Spin spinning={loading}>               
                {
                  buyerDefinitions && buyerDefinitions.map((item:any, index:any) => {
                    return (
                      <li key={index} style={{display:'flex', alignItems:'center'}}>
                        <Form.Item style={{minWidth:'140px'}} className="fnns-name"> 
                          <label >{item.financialInstitutionName}</label>
                        </Form.Item>
                        <Form.Item 
                          name=  {`${index}limit`} 
                          className="dev-input"    
                        >
                        <Input  
                         style={{minWidth:'160px', paddingLeft:'5px',maxWidth:'160px'}}
                          name={`${index}limit`}  
                          key= {`${index}id`}  
                          onInput={(e:any) => {
                            setInputValue((prevArr:any) => {
                              const result = [...prevArr];
                              result[index] = e.target.value;
                              return result;
                            });
                          }}
                          placeholder={convertCurrencyTl(item.limit)}
                          />
                      </Form.Item>
                      <Form.Item 
                          name=  {`${index}valor`} 
                          className="dev-input"    
                          style={{width:'60px', paddingLeft:'5px', minWidth:'60px', maxWidth:'60px'}}
                        >
                        <Input  
                          name={`${index}valor`}  
                          key= {`${index}id`}  
                          disabled= {true}
                          type="number"
                          max={3}
                          min={0}
                          value={valorValue[index]}
                          onInput={(e:any) => {
                            setValorValue((prevArr:any) => {
                              const result = [...prevArr];
                              result[index] = e.target.value;
                              return result;
                            });
                          }} 
                          placeholder={item.delayValue}
                          />
                      </Form.Item>
                      <Form.Item
                         style={{ paddingLeft:'10px', minWidth:'140px',maxWidth:'140px'}}
                      >
                        <label>{convertCurrencyTl(item.risk)}</label>
                      </Form.Item>
                      {
                        item.financialInstitutionTaxId === '0150015264'  ? 
                        (
                          <Form.Item 
                              name=  {`${index}companyCode`} 
                              className="dev-input"    
                            >
                            <Input  
                            style={{minWidth:'200px', paddingLeft:'5px'}}
                              name={`${index}companyCode`}  
                              key= {`${index}id`}  
                              onInput={(e:any) => {
                                setCompanyCode((prevArr:any) => {
                                  const result = [...prevArr];
                                  result[index] = e.target.value;
                                  return result;
                                });
                              }}
                              placeholder={item.companyCode !== '' ? item.companyCode : `${item.financialInstitutionName} CompanyCode`} 
                              />
                          </Form.Item>
                        ) : ''
                      }      
                  </li>
               )
                })
                }
                </Spin> 
              </ul> 
              </div>
             
            <Button type="default"  onClick={showModal} style={{marginBottom:'20px'}} >Finans Kurumu Ekle</Button>

            <div>
              <Form.Item name="autoMarkInvoicesAsPaidEnabled" style={{marginBottom:'0'}} >
                  <Checkbox  checked={check}  onChange={onChange}>Otomatik Fatura Ödendi Olarak İşaretle</Checkbox>
                </Form.Item>
                <div style={{display:'flex'}}>
                  <Form.Item name="isIntegrated" style={{marginBottom:'0'}} >
                    <Checkbox  checked={checkIntegration}  onChange={onChangeIntegration}>Entegre Alıcısı</Checkbox>
                  </Form.Item>
                  {
                    checkIntegration ? 
                    <div>
                      <Button 
                        className="automatic-billing-information" onClick={AutomaticBillingInformation}
                        >Otomatik Fatura Bilgilerini Çek</Button>
                     </div>
                    : ''
                  }
                </div> 
            </div>

            <Form.Item style={{marginTop:'25px'}}>
                <Button type="primary"  htmlType="submit">Alıcı Bilgisi Güncelle</Button>
              </Form.Item>
          </Form>
          <Modal title="Finans Kurumları" className="finance-add-modal"  
            open={isModalOpen} 
            closable={false} 
            okText="Ekle"
            cancelText="İptal"
            footer={[
              <Button key="ok" type="primary" onClick={handleOk} disabled={checkedItemsCount === 0}>
                Ekle
              </Button>,
               <Button key="ok" type="primary" onClick={handleCancel}>
               İptal
             </Button>,
            ]}
          >
           <Checkbox.Group style={{ width: '100%' }}>
            <ul>
              {
               undefinedFinancial.length < 1 ?
               'Eklenecek finans kurumu bulunmamaktadır.'
               : 
               undefinedFinancial.length && undefinedFinancial.map((item:any,index:any) => {
                return(
                  <li key={index}>
                    <Checkbox value={item} checked={!!checkedItems[item.id]}
              onChange={() => handleCheckboxChange(item)}>{item.name}</Checkbox>
                  </li>
                )
                })
              }
            </ul>
          </Checkbox.Group>
          </Modal>
        </Col>
      </Row>
     
    </main>
  );
};


export default BuyerUpdate;