import {apiBuyer} from '../services/apis';
// import {LocalStorageService} from '../helpers/LocalStorage';

const getBuyersListApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers`,
    {}
  );
};

const getBuyerstApi = async (page:any,psize:any,searchQuery:any): Promise<Object> => {
  return searchQuery ?
   await apiBuyer.get(`admin/buyers/paged?pageNumber=${psize}&pageSize=${page}&searchQuery=${searchQuery}`,
   {}
   ):
   await apiBuyer.get(`admin/buyers/paged?pageNumber=${psize}&pageSize=${page}`,
    {}
  );
};


const getBuyerUsersApi = async (buyerId: any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyer/${buyerId}/users`,
    {}
  );
};

const deleteBuyerUserApi = async (buyerUserId: any): Promise<Object> => {
  return await apiBuyer.delete(`admin/buyer-user/${buyerUserId}`,
    {}
  );
};

const buyerUserSaveApi = async (newData: any): Promise<Object> => {
  return (
    await apiBuyer.post(`admin/buyer-user`,newData , {})
  );
};

const getBuyerDetailApi = async (buyerId: any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyer/${buyerId}`,
    {}
  );
};

const updateBuyerLimitApi = async (buyerId:any, newData: any): Promise<Object> => {
  return await apiBuyer.patch(`admin/buyer/${buyerId}/limit`, newData, {} );
};

const updateBuyerApi = async (buyerId:any, newData: any): Promise<Object> => {
  return await apiBuyer.patch(`admin/buyer/${buyerId}`, newData, {} );
};

const getUploadInvoiceApi = async (buyerId:any, buyerTitle:any, file: any): Promise<Object> => {
  return await apiBuyer.post(`admin/buyer/${buyerId}/invoices`, file,   {  
    headers: 
        {
            // 'X-Token': LocalStorageService.getAuthToken(),
            'x-buyer-id': buyerId,
            'x-buyer-title':buyerTitle
        }
        });
};

const getUploadeMustahsilApi = async (buyerId:any, buyerTitle:any, file: any): Promise<Object> => {
  return await apiBuyer.post(`admin/buyer/${buyerId}/invoices/agriculturist`, file,   {  
    headers: 
        {
            // 'X-Token': LocalStorageService.getAuthToken(),
            'x-buyer-id': buyerId,
            'x-buyer-title':buyerTitle
        }
        });
};

const uploadXmleMustahsilApi = async (buyerId:any,invoices: any): Promise<Object> => {
  return (
    await apiBuyer.post(`admin/buyer/${buyerId}/invoices/list`,{invoices} , {})
  );
};

const getUploadSupplierApi = async (buyerId:any, buyerTitle:any, file: any): Promise<Object> => {
  return await apiBuyer.post(`admin/buyer/${buyerId}/suppliers`, file,   {  
    headers: 
        {
            // 'X-Token': LocalStorageService.getAuthToken(),
            'x-buyer-id': buyerId,
            'x-buyer-title':buyerTitle
        }
        });
}; 

const createBuyerIdentifyApi = async (newData: any): Promise<Object> => {
  return (
    await apiBuyer.post(`admin/buyer`,newData , {})
  );
};

const deleteBuyerApi = async (buyerId: any): Promise<Object> => {
  return await apiBuyer.delete(`admin/buyer/${buyerId}`,
    {}
  );
};

const getSupplierLimitDatas = async (buyerId: any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyer/${buyerId}/suppliers/limits`,
    {}
  );
};

const updatedSupplierLimit = async (newData: any): Promise<Object> => {
  return await apiBuyer.patch(`admin/suppliers/limits`, newData, {} );
};

const getBuyerUserApi = async (buyerId: any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyer/user/${buyerId}`, {} );
};

const buyerUserUpdateApi = async (newData: any, buyerUserId:any): Promise<Object> => {
  return (
    await apiBuyer.patch(`admin/buyer-user/${buyerUserId}`,newData , {})
  );
};

const getInvoicesApi = async (filters: string, psize:any,page:any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/invoices?${filters ? `${filters}`:''}pageNumber=${page}&pageSize=${psize}`, {} );
};

const getBuyersTitleApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/titles`, {} );
};

const getUndefinedFinancialApi = async (buyerId: any): Promise<Object> => {
  return await apiBuyer.get(`admin/${buyerId}/undefined-financial-institutions`,
    {}
  );
};

const getSuppliersTitleApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/supplier-titles`, {} );
};

const getInvoicesXlsxApi = async (filters: string, psize:any,page:any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/invoices/xlsx?${filters ? `${filters}`:''}pageNumber=${page}&pageSize=${psize}`, {} );
};

const forgotPasswordApi = async (data: any): Promise<Object> => {
  return (
    await apiBuyer.post(`admin/send-email/create-or-reset-password`,data , {})
  );
};

const getSupplierListApi = async (buyerId:any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/${buyerId}/suppliers/registered`,
    {}
  );
};

const getBuyerRiskHistoryApi = async (buyerId:any, supplierId:any, startDate:any, finishDate:any, pageNumber:any, pageSize:any ): Promise<Object> => {
  return await apiBuyer.get(`admin/risks/buyers/${buyerId}/suppliers/${supplierId}/history?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getXlsxDonwloadApiApi = async (buyerId:any, supplierId:any, startDate:string, finishDate: string, pageNumber:any, pageSize:any): Promise<Object> => {
  return  await apiBuyer.get(`admin/risks/buyers/${buyerId}/suppliers/${supplierId}/history/xlsx?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getUnregisteredBuyersTitleApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/suppliers/unregistered/buyer-titles`,
    {}
  );
};

const getUnregisteredSuppliersTitleApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/unregistered/supplier-titles`,
    {}
  );
};

const getUnregisteredSuppliersApi = async (filters: string, psize:any,page:any): Promise<Object> => {
  return await apiBuyer.get(`admin/unregistered/suppliers?${filters ? `${filters}`:''}pageNumber=${page}&pageSize=${psize}`, {} );
};

const getInvoicesUnusetApi = async (filters: string, pageSize: number, offset: number, buyerId:any): Promise<Object> => {
  return await apiBuyer.get(`admin/unused/${buyerId}?${filters ? `${filters}` : ''}pageNumber=${offset}&pageSize=${pageSize}`, {});
};

const updateTermInvoiceApi = async (data: any,  buyerId:any): Promise<Object> => {
  return  await apiBuyer.patch(`admin/term/${buyerId}`, data, {} )
};

const getFarmersLimitDatas = async (buyerId: any): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/${buyerId}/agriculturists/limits`,
    {}
  );
};

const updatedAgriculturistsLimit = async (newData: any, buyerId:any): Promise<Object> => {
  return await apiBuyer.patch(`admin/buyers/${buyerId}/agriculturists/limit`, newData, {} );
};

const dummyDataDeleteApi = async (email: any): Promise<Object> => {
  return await apiBuyer.delete(`admin/dummy-data?email=${email}`,
    {}
  );
};

const getAgriculturistListApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/agriculturist`,
    {}
  );
};

const getFarmersBuyerRiskHistoryApi = async (buyerId:any, farmerId:any, startDate:any, finishDate:any, pageNumber:any, pageSize:any ): Promise<Object> => {
  return await apiBuyer.get(`admin/risks/buyers/${buyerId}/agriculturists/${farmerId}/history?openingDateBegin=${startDate}${finishDate ? `&openingDateEnd=${finishDate}`: ''}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getDownloadSampleFileApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/buyer/agriculturist/sample/template`,
    {}
  );
};

const getDownloadSampleSupplierFileApi = async (): Promise<Object> => {
  return await apiBuyer.get(`admin/buyers/supplier/sample/template`,
    {}
  );
};

const getUploadFarmerApi = async (buyerId:any, buyerTitle:any, file: any): Promise<Object> => {
  return await apiBuyer.post(`admin/buyer/${buyerId}/agriculturists`, file,   {  
    headers: 
        {
            // 'X-Token': LocalStorageService.getAuthToken(),
            'x-buyer-id': buyerId,
            'x-buyer-title':buyerTitle
        }
        });
}; 

const automaticBillingInformationApi = async (taxId: any): Promise<Object> => {
  return (
    await apiBuyer.post(`admin/buyers/upload-invoice-for-integrated-buyer/${taxId}`, {})
  );
};

const getReportHistoriesApi = async (filters:string, pageNumber:any, pageSize:any ): Promise<Object> => {
  return await apiBuyer.get(`admin/invoices/histories?${filters ? `${filters}`:''}pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {}
  );
};

const getHistoriesXlsxApi = async (filters: string, psize:any,page:any): Promise<Object> => {
  return await apiBuyer.get(`admin/invoices/histories/xlsx?${filters ? `${filters}`:''}pageNumber=${psize}&pageSize=${page}`, {} );
};

const uploadInvoiceIntegratedBankApi = async (buyerId: any): Promise<Object> => {
  return (
    await apiBuyer.post(`admin/buyers/upload-invoice-for-integrated-bank/${buyerId}`, {})
  );
};


export {
  getBuyersListApi,
  getBuyerUsersApi,
  deleteBuyerUserApi,
  buyerUserSaveApi,
  getBuyerDetailApi,
  updateBuyerLimitApi,
  getUploadInvoiceApi,
  getUploadSupplierApi,
  createBuyerIdentifyApi,
  deleteBuyerApi,
  updateBuyerApi,
  getSupplierLimitDatas,
  updatedSupplierLimit ,
  getBuyerUserApi,
  buyerUserUpdateApi,
  getInvoicesApi,
  getBuyersTitleApi,
  getUndefinedFinancialApi,
  getSuppliersTitleApi,
  getInvoicesXlsxApi,
  getBuyerstApi,
  forgotPasswordApi,
  getSupplierListApi,
  getBuyerRiskHistoryApi,
  getXlsxDonwloadApiApi,
  getUnregisteredBuyersTitleApi,
  getUnregisteredSuppliersTitleApi,
  getUnregisteredSuppliersApi,
  getInvoicesUnusetApi,
  updateTermInvoiceApi,
  getFarmersLimitDatas,
  updatedAgriculturistsLimit,
  dummyDataDeleteApi,
  getUploadeMustahsilApi,
  uploadXmleMustahsilApi,
  getAgriculturistListApi,
  getFarmersBuyerRiskHistoryApi,
  getDownloadSampleFileApi,
  getUploadFarmerApi,
  automaticBillingInformationApi,
  getReportHistoriesApi,
  getHistoriesXlsxApi,
  uploadInvoiceIntegratedBankApi,
  getDownloadSampleSupplierFileApi

};
