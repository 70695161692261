import React,{useEffect} from "react";
import { Row, Col} from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { urls } from "../router/auth";
import {setUserData} from "../reducers/userSlice";
import { LocalStorageService } from "../helpers/LocalStorage";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setUserData(' ')); 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
},[])

  return (
    <main>
      <Row>
        <Col span={24} className="home-page">
          <h3 className={`page-title ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Admin</h3>
          <div className="container-div">
            <Link to={urls.newUser} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Kullanıcı Oluştur</Link>
            <Link to={urls.userTracing} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Kullanıcı Listesi</Link>
            <Link to={urls.transactionReconciliationReport} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>İşlem Mutabakat Raporu</Link>
          </div>
          <h3 className={`page-title ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Bildirim Yönetimi</h3>
          <div className="container-div">
            <Link to={urls.templateCreate} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Şablon Oluştur</Link>
            <Link to={urls.templateEdit} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Şablon Düzenle / Sil</Link>
            <Link to={urls.testMailSend} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Test Maili Gönderme</Link>
            <Link to={urls.dataTransfer} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Veri Aktarma</Link>
          </div>
          <h3 className="page-title">Tedarikçi</h3>
          <div className="container-div">
            <Link to={urls.accountTransactions} className="item">Tedarikçi Listesi</Link>
            <Link to={urls.transactionsHistory} className="item">İşlem Geçmişi</Link>
            <Link to={urls.supplierRiskMovements} className="item">Tedarikçi Risk Hareketleri</Link>
            <Link to={urls.applicantSuppliers} className="item">Aday Tedarikçiler</Link>
          </div>
          <h3 className="page-title">Çiftçi</h3>
          <div className="container-div">
            <Link to={urls.farmersAccountTransactions} className="item">Çiftçi Listesi</Link>
            <Link to={urls.farmersTransactionsHistory} className="item">İşlem Geçmişi</Link>
            <Link to={urls.farmersRiskMovements} className="item">Çiftçi Risk Hareketleri</Link>
            <Link to={urls.applicantFarmers} className="item">Aday Çiftçiler</Link>
          </div>
          <h3 className="page-title">Finansal Kurum</h3>
          <div className="container-div">
            <Link to={urls.corporationSave} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Hesap Açılış İşlemleri</Link>
            <Link to={urls.corporationUserSave}  className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Kullanıcı Tanımlama</Link>
            <Link to={urls.corporationsList}  className="item">Kurumlar Listesi</Link>
            <Link to={urls.riskLimitInformation}  className="item">Risk/Limit Bilgileri</Link>  
          </div>

          <h3 className="page-title">Alıcı</h3>
          <div className="container-div">
            <Link to={urls.identification} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Alıcı Tanımlama</Link>
            <Link to={urls.userIdentificationSave} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Alıcı Kullanıcısı Tanımlama</Link>
            <Link to={urls.buyersList}  className="item">Alıcılar Listesi</Link>
            <Link to={urls.buyerUploadInvoice} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Alıcı Adına Fatura Yükleme</Link>
            <Link to={urls.buyerUploadeMustahsil} className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Alıcı Adına E-Müstahsil Yükleme</Link>
            <Link  to={urls.buyerUploadSupplier}  className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Alıcı Adına Tedarikçi Yükleme</Link>
            <Link  to={urls.buyerUploadFarmer}  className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Alıcı Adına Çiftçi Yükleme</Link>
            <Link to={urls.invoicesstatus}  className="item">Faturaların Durumu</Link>
            <Link to={urls.invoiceMaturityUpdate}  className={`item ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}>Fatura Vadesi Güncelleme</Link>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default HomePage;
