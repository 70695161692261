import React, { useState, useEffect } from "react";
import { Row,Col, Table, Button, Modal, notification} from "antd";
import { getCorporationsListPagedApi, deleteFinance } from "../../services/financeApi";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "../../helpers/LocalStorage";

function CorporationsList() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [financialInstitutions, setFinancialInstitutions] = useState<any>();
  const [deletefinance, setDeleteFinance] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);

  const columns : any = [
    {
      title: 'Kurum Ünvan',
      dataIndex: 'name',
      key: "name",
      fixed: 'left',
      width: 250,
    },
    {
      title: 'VKN',
      dataIndex: 'taxId',
      key: "taxId",
      width: 120,  
    },
    {
      title: 'Adres',
      dataIndex: 'invoiceAddress',
      key: "invoiceAddress",
    },
    {
      title:'',
      dataIndex:'detail',
      key:'detail', 
      width: 120,      
      render: (text: any, row: any) => 
        <Button 
          type="primary"
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={()  => navigate(`/financial-institution-user/${row?.id}`)}
        > 
          Güncelle
        </Button>           
      },
      {
        title:'',
        dataIndex:'users',
        key:'users', 
        width: 120,      
        render: (text: any, row: any) => 
           <Button 
              type="primary"
              onClick={()  => navigate(`/corporations-users-list/${row?.id}`)}
              > 
              Kullanıcılar
            </Button>         
    },
    {
      title:'',
      dataIndex:'sil',
      key:'sil',     
      width: 120,  
      render: (text: any, row: any) =>  
        <Button 
          style={{backgroundColor:'#F15A31', border:'#f25429'}}
          type="primary" 
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={ () =>  showModal(row.id) }
          > Sil
        </Button>         
    },
  ];
  
  
  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  const getCorporationsList =  async () => {
    try {
      setLoading(true);
      const response: any = await getCorporationsListPagedApi(psize,page);
      if (response) {
        setLoading(false);
        setFinancialInstitutions(response.financialInstitutions.data);
        setTotalDataCount(response.financialInstitutions.totalDataCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }

 

  const deleteCorporationUser = async () => {
    if(deletefinance){
      try {
        await deleteFinance(deletefinance);
          notification.success({
            message: `Finans Kurumu başarıyla silinmiştir.`,
        }); 
          getCorporationsList();
          setDeleteFinance('');
        
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  const showModal = (id:any) => {
    setOpen(true);
    setDeleteFinance(id)
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    deleteCorporationUser();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    getCorporationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [psize,page])

 

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Kurumlar Listesi</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Table
           className="w-100 invoiceTable"
           pagination={{
             position: ["topRight", "none" as any],
             showLessItems: true,                          
             showSizeChanger: true,
             current: page,
             pageSize:psize,
             total:totalDataCount, 
             onChange: handleShowSize,
             
           }}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={financialInstitutions}
          scroll={{
            x: 1200,
          }}
          
        />
        </Col>
      </Row>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>İşlemi yapmak istediğinizden emin misiniz?</p>
      </Modal>
    </main>
  );
};


export default CorporationsList;
