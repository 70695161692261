import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, notification,Typography, InputNumber} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import {convertCurrencyTl} from "../../helpers/common";
import { getFarmersLimitDatas, updatedAgriculturistsLimit, getBuyersListApi} from "../../services/buyerApi";
import { LocalStorageService } from "../../helpers/LocalStorage";

function BuyerUserLimitUpdate() {
  const [form] = Form.useForm();
  const location = useLocation();
  const buyerId = location.pathname.split('/')[2];

  const [agriculturistInformations, setAgriculturistInformations] = useState<any>([]); 
  const [agriculturistLimit, setAgriculturistLimit] = useState<any>([]);
  const [financialInstitutionId, setFinancialInstitutionId] = useState<any>('');

 
  const [inputValue, setInputValue] = useState<any>();
  const [buyerTitle, setBuyerTitle] = useState<any>([]);


  const { Text } = Typography;
 
  
  // interface FarmersLimitsUpdate {      
  //   agriculturistLimits: Array<FarmersLimits>;  
  //   financialInstitutionId : string;
  //   agriculturistId : string;   
  // }
  // interface FarmersLimits {      
  //   agriculturistId: string; 
  //   limit: number; 
  //   financialInstitutionId : string;
  // }  
  // let newData = {} as FarmersLimitsUpdate;
  // newData.agriculturistLimits = [];
  // newData.financialInstitutionId = '';
  // newData.agriculturistId = ''

  const getBuyerList =  async () => {
    try {
      const response: any = await getBuyersListApi();
      if (response) {
        setBuyerTitle(response.buyers.filter((item:any) => item.id === buyerId));
      }
    } catch (error: any) {
      // console.log(error);
    }
  }

  const fetchAllSuppliers  = async () => {
    try {
      const response: any = await getFarmersLimitDatas(buyerId);
      if (response) {
        setAgriculturistInformations(response.agriculturistInformations); 
        setAgriculturistLimit(response.agriculturistInformations.limits);
        setFinancialInstitutionId(response.agriculturistInformations.financialInstitutionId)
      }
    } catch (error: any) {      
      // console.log(error) 
    }
  }

  const updateFinanceLimit = async ( agriculturistId:any) => {  
    const newData = {
      financialInstitutionId: financialInstitutionId,
      agriculturistId:agriculturistId,
      limit:inputValue
    }

    if(inputValue !== '' || inputValue !== undefined){
      try{
        await updatedAgriculturistsLimit(newData, buyerId);    
        notification.success({message: 'Limit başarıyla güncellenmiştir.'});  
        window.location.reload();         
      }catch(e:any){

        if(e.data.Status === 406 && e.data.Type.includes('BuyerLimitExcessException')){
          notification.warning({message: 'Tedarikçi Limiti Alıcı Limitinden Fazla Olamaz.'});
        }  
        if(e.data.Status === 406 && e.data.Type.includes('InvalidSupplierLimitException')){
          notification.warning({message: "Tedarikçi Limiti 0' dan küçük olamaz" });
        }        
      }     
    }if(inputValue === undefined){
      notification.success({message: 'Güncellenecek limit girmediniz.'});
    }    
  }

  const clearInput = (row:any) =>{
    form.setFieldValue(row.target.id, '');
  }

  useEffect(() => {
    getBuyerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId]);
 

  useEffect(() => {
    fetchAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  return (
    <main>
      <Row >
        <Col span={24}>
          <h3 className="page-title"> Alıcı Çitfçi Limit Güncelleme</h3>
        </Col>
      </Row>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3> Alıcı : {buyerTitle && buyerTitle[0]?.title}</h3>
        </Col>
      </Row>
      <Row>
        <Text style={{fontWeight:'600'}}>Toplam limitinizi Çitfçileriniz arasında bölüştürün.</Text>       
      </Row>
      <Row>
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
            Banka Risk/Limit Durumu 
          </Text>
      </Row>
      <Row className="limit-header-title">
          <Col span={24}>
              <div className="title buyer-definitions-title">
                  <div>Finans Kurumu</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  {/* <div>Tedarikçi <br/> Adedi</div> */}
              </div>
              <div className="table-li buyer-definitions-li" style={{marginLeft:'0'}}>
                {
                      <div>
                        <div>{agriculturistInformations.financialInstitutionName}</div>
                        <div>{convertCurrencyTl(agriculturistInformations.buyerRisk)} </div>
                        <div>{convertCurrencyTl(agriculturistInformations.buyerLimit)} </div>
                        <div>{agriculturistInformations.buyerRisk === 0 ? '-' : ((agriculturistInformations.buyerRisk * 100)/agriculturistInformations.buyerLimit)?.toFixed(2)} %</div>
                        {/* <div>{item.supplierCount}</div>                            */}
                    </div>
                  
                }            
                
                {/* <div>
                  <div>TOPLAM</div>
                  <div>{bankRiskTotal && convertCurrencyTl(bankRiskTotal)} </div>
                  <div>{bankLimitTotal && convertCurrencyTl(bankLimitTotal)} </div>
                  <div>{bankRiskTotal === 0 ? '-' : (bankRiskTotal && bankLimitTotal && (bankRiskTotal * 100)/bankLimitTotal)?.toFixed(2)} %</div>
                  <div>{supplierCountTotal && supplierCountTotal}</div>
                </div> */}
              </div>
          </Col>
        </Row>
      <Row style={{marginTop:'30px'}}>        
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
          Çitfçi Risk/Limit Durumu ve Güncelleme
        </Text>
        <Text style={{marginTop:'15px', fontStyle:'italic'}}><b>"Toplam Limitinizi "</b>Çitfçileriniz arasında uygun gördüğünüz şekilde bölüştürebilirsiniz. Herhangi bir Çitfçiye vereceğiniz limit en çok <b>“Toplam Limitiniz”</b> kadar olabilir. Ancak tüm Çitfçilerinize toplam kullandırımınız <b>“Toplam Limitiniz”</b>i aşamaz.</Text>
      </Row> 
      {/* <Row style={{marginTop:'20px'}}>
          <Col span={10}>
            <Form
               name="filter-box"
              //  onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row'>                
                <div>
                  <Form.Item name="title" >
                    <Input 
                      placeholder="Tedarikçi Adı veya VKN'sini Seçiniz"
                      style={{minWidth:'450px'}}
                     />
                    </Form.Item>
                </div>               
                <div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"                 
                    >Ara</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
      </Row> */}
       <div className="limit-header-title-mobil">
        <Row className="limit-header-title">
          <Col span={24}>
              <div className="title title-mobil" style={{width:'auto', borderBottomLeftRadius:'0', borderBottomRightRadius:'0'}}>
                  <div>Çiftçi Adı</div>
                  <div>VKN No</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Platforma <br/> Kayıt Durumu</div>
              </div>
          </Col>
        </Row>
        <Row >
          <Col span={24}>
          <div className="table-li table-li-mobil limit-acc-style" style={{width:'auto'}}>                   
                      {
                        agriculturistLimit.map((item:any, i:any) => {
                          return(
                            <div>
                              <div>{item.nameSurname}</div> 
                              <div>{item.taxNumber}</div>
                              <div>{convertCurrencyTl(item.risk)} TL</div>
                              <div>
                                  <Form form={form} className="supplier-limit-input" style={{display:'flex'}}>
                                    <Form.Item 
                                      name={`limit${i}` }
                                      className='hide-arrow'
                                      >
                                      <InputNumber                                        
                                        formatter={(val) => ` ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",")} 
                                        parser={(val:any) => val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")}
                                        defaultValue={`${convertCurrencyTl(item.limit)}`}
                                        onChange={(val:any) => setInputValue(val)} 
                                        onClick={(row:any) => clearInput(row)}
                                        className="no-spin input-width"
                                        style={{borderRadius:'8px'}}                 
                                        type='text' 
                                        disabled={LocalStorageService.getUserRole() !== 'SUPERVISOR'}
                                      />           
                                    </Form.Item>
                                  <Form.Item>
                                    <Button 
                                      className={`supplier-limit-btn ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}
                                      onClick={() => updateFinanceLimit( item.id ) } 
                                    >
                                      Güncelle
                                    </Button>
                                  </Form.Item>
                                </Form>                                
                              </div>
                              <div>{item.risk === 0 ? '-' : ((item.risk * 100)/item.limit)?.toFixed(2)} % </div>  
                              <div>
                              {
                                item.isRegistred 
                                ? <CheckCircleOutlined style={{color: '#62b61f', fontSize: 22}} /> 
                                : <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22}} />
                              }
                              </div>                         
                            </div>
                          )
                        })
                      }
                    </div>   

          </Col>       
        </Row>
      </div> 
     
    </main>
  );
};


export default BuyerUserLimitUpdate;
