import React,{useEffect, useState} from "react";
import { Row, Col, Table, Button, Modal } from "antd";
import { getTemplatesNameApi, getTemplatesDeleteApi } from "../../services/managementApi";
import { useNavigate } from "react-router-dom";

const TemplateEdit = () => {
  let navigate = useNavigate();
  const [templateName, setTemplateName] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();

  const getTemplateList = async () => {
    try {
      setLoading(true)
      const response: any = await getTemplatesNameApi();
      if(response){
        setLoading(false);
        setTemplateName(response.templates);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false)
    }
  }

  const showModal = (id:any) => {
    setOpen(true);
    setDeleteId(id)
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    deleteTemplate();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const deleteTemplate = async () => {
    try {
      await getTemplatesDeleteApi(deleteId);
      getTemplateList();
    } catch (e: any) {
      console.log(e);
      setLoading(false)
    }
  }

  useEffect(() => {
   getTemplateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const columns : any = [
    {
      title:'Şablon Adı',
      dataIndex: 'name',
      key:'name',
      fixed:'left',
    },
    {
      title:'',
      dataIndex:'nameUpdate',
      key:'nameUpdate', 
      width:120,    
      render: (text: any, row: any) => 
        <Button 
          type="primary"
          onClick={()  => navigate(`/template-edit-name/${row?.id}`)}
        > 
          Şablon Adı Düzenle
        </Button>           
      },
      {
        title:'',
        dataIndex:'contentUpdate',
        key:'contentUpdate',    
        width:180, 
        render: (text: any, row: any) => 
          (
            <Button 
            type="primary"
            onClick={()  => navigate(`/template-edit-content/${row?.id}`)}
          > 
            Şablon İçerik Düzenle
          </Button>  
          )       
        },
      {
        title:'',
        dataIndex:'variablesUpdate',
        key:'variablesUpdate',  
        width:120,   
        render: (text: any, row: any) => 
           <Button 
              type="primary"
              onClick={()  => navigate(`/template-edit-variables/${row?.id}`)}
              > 
              Şablon Etiket Düzenle
            </Button>         
    },
    {
      title:'',
      dataIndex:'delete',
      key:'delete',  
      width:120,   
      render: (text: any, row: any) => 
         <Button 
            style={{backgroundColor:'#F15A31', border:'#f25429'}}
            type="primary"
            onClick={()  => showModal(row?.id)}
            > 
            Şablon Sil
          </Button>         
  },
  ];


  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Şablon Listele / Düzenle</h3>
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Table
           className="w-100 invoiceTable"
          //  pagination={{
          //    position: ["topRight", "none" as any],
          //    showLessItems: true,                          
          //    showSizeChanger: true,
          //   //  current: page,
          //   //  pageSize:psize,
          //   //  total:totalDataCount, 
          //   //  onChange: handleShowSize,
             
          //  }}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={templateName}
          // scroll={{
          //   x: 1200,
          // }}
          
        />
        </Col>
      </Row>
      <Modal
        className="template-modal"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>Şablonu silmek istediğinizden emin misiniz?</p>
      </Modal>
    </main>
  );
};

export default TemplateEdit;
