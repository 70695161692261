import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input,notification} from "antd";
import { useLocation } from "react-router-dom";
import { getAgriculturistDetailApi,updateAgriculturistApi} from "../../services/agroApi";
import {validateVkn,validateGsmNumber} from "../../helpers/validators";


const AccountTransactionsUpdate = () => { 
 
  const location = useLocation();
  const farmersId = location.pathname.split('/')[2];
  const [form] = Form.useForm();
  const [agriculturistlInfo, setAgriculturistInfo] = useState<any>();

  const onFinish =  async (values:any) => {
    const userData : any = {
      name : values.name !==  undefined || '' ? values.name : agriculturistlInfo?.name,
      surname : values.surname !==  undefined || '' ? values.surname : agriculturistlInfo?.surname,
      gsmNumber : values.phone !==  undefined || '' ? values.phone : agriculturistlInfo?.gsmNumber,
      taxNumber: values.taxId !==  undefined  || '' ? values.taxId : agriculturistlInfo?.taxId
    };

    try {
        await updateAgriculturistApi(farmersId, userData);
          notification.warning({
            message: `Başarıyla güncellenmiştir.`,
        });  
        window.location.reload();
        
      } catch (error: any) {
        console.log(error);
        if(error.data.Status === 409 && error.data.Type.includes('TaxIdAlreadyExistsException')){
          notification.warning({message: `${values.taxId} vergi numarasına sahip çiftçi zaten mevcut.` });
        } 
        if(error.data.Status === 409 && error.data.Type.includes('PhoneNumberAlreadyExistsException')){
          notification.warning({message: `${ values.phone} telefon numarasına sahip çiftçi zaten mevcut.` });
        } 
      }
  };
  
const getAgriculturistInfo =  async (farmersId:any) => {
  try {
    const response: any = await getAgriculturistDetailApi(farmersId);
      if (response) {
        setAgriculturistInfo(response.agriculturist);
      } 
    } catch (error: any) {
      console.log(error);
 
    }
}

useEffect(() => {
    if(farmersId){
      getAgriculturistInfo(farmersId);
    }
  },[farmersId])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Çiftçi Güncelleme</h3>
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Form
          form={form}
          name="user-update"
          className="default-form"
          onFinish={onFinish}
          labelCol={{
          span: 10,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="vertical"
          initialValues={{
            size: 'large',
          }}
          size={'large'}
          style={{
            width: '100%',
            display:'flex'
          }}
          >
       
          <div className="form-col-box" style={{paddingTop:'0'}}>

            <Form.Item 
              label="Vergi Kimlik Numarası:" 
              name="taxId"
              rules={[
                {
                  validator: (_, value) =>  validateVkn(value ? value : agriculturistlInfo?.taxId )
                },
              ]}
              >
              <Input placeholder={agriculturistlInfo?.taxId}/>
            </Form.Item>
            <Form.Item 
              label="Ad:" 
              name="name"
              >
              <Input placeholder={agriculturistlInfo?.name}/>
            </Form.Item>
            <Form.Item 
              label="Soyad:" 
              name="surname"
              >
              <Input placeholder={agriculturistlInfo?.surname}/>
            </Form.Item>

            <Form.Item 
              label="Telefon Numarası:"
              name="phone"
              rules={[
                {
                  validator: (_, value) =>  validateGsmNumber(value ? value : agriculturistlInfo?.gsmNumber )
                },
              ]}
            >
              <Input placeholder={agriculturistlInfo?.gsmNumber}  />
            </Form.Item>

            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Güncelle</Button>
            </Form.Item>
            </div>
        </Form>
        </Col>
      </Row>
    </main>
  );
};

export default AccountTransactionsUpdate;
