import React, {useState, useEffect } from "react";
import { Row,  Col } from "antd";
import { getAgriculturistDetailApi } from "../../services/agroApi";
import { useLocation } from "react-router-dom";

function AccountTransactionsDetail() {
  const location = useLocation();
  const discountsId = location.pathname.split('/')[2];
  const [detail, setDetail] = useState<any>();

  const getAgriculturistDetail  = async () => {  
    try {
      const response: any = await getAgriculturistDetailApi(discountsId);
      if (response) {
        setDetail(response) 
    }
    } catch (error: any) {
      console.log(error);
    }
  }

useEffect(() => {
  getAgriculturistDetail();
 // eslint-disable-next-line react-hooks/exhaustive-deps   
}, [])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Çiftçi Detay</h3>
        </Col>
      </Row>
      <Row> 
        <Col span={24} style={{paddingRight:'40px', marginTop:'20px'}}>
          <ul className="left-item">
            <li>
              <span>Çiftçi VKN/TCKN</span>
              <span>{detail?.agriculturist?.taxId}</span>
            </li>
            <li>
              <span>Ad Soyad:</span>
              <span>{detail?.agriculturist?.name} {detail?.agriculturist?.surname}</span>
            </li>
            <li>
              <span>Cep Telefonu:</span>
              <span>{detail?.agriculturist?.gsmNumber}</span>
            </li>
          </ul>
        </Col>
      </Row>
    
    </main>
  );
};

export default AccountTransactionsDetail;
